:root {
  --primary: #1c2931;
  --secondary: #00baab;
  --font-family: "Source Sans Pro", sans-serif;
  --aside-background: #1d2932;
  --font-color: #444;
  --header-background: #f5f8f8;
  --border-color: #ddd;
  --border-size: 2px;
  --disabled-color: #ddd;
  --layer-background: rgba(0, 0, 0, 0.4);
  --input-border-color: rgba(0, 0, 0, 0.6);
  --error: rgba(242, 0, 29, 0.5);
  --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-tap-highlight-color: #00a9ac55;
  --background: #f5f5f5;
  --inside-color: #fff;
  --placeholder-color: #ddd;
  --font-color-on-background: #1f1f1f;
  --gold-color: #ffb300;
  --square-bottom-color: transparent;
  --border-radius: 4px;
  --square-header-color: var(--secondary);
}

.faq-header,
.raw-header,
.square__header {
  background: var(--square-header-color);
  border-bottom: 1px solid var(--square-bottom-color);
}

.dark input {
  color-scheme: dark;
}

.bg-white {
  background: var(--inside-color) !important;
}

.bg-secondary {
  background: var(--secondary);
}

::placeholder {
  color: var(--placeholder-color);
}

.border-b-1:last-child {
  border-bottom: none;
}

.square__see-all {
  border-top: 1px solid var(--border-color);
}

.border-top {
  border-top: 1px solid var(--border-color);
}

.border-right {
  border-right: 1px solid var(--border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.pc-swal__title {
  color: var(--font-color);
}

html {
  min-height: 100%;
  overflow-y: auto;
}

body {
  display: flex;
  flex-direction: column;
  background: var(--background);
  color: var(--font-color);
}

.pc-list-item p,
select,
.font-color,
.pc-select__span,
.pc-input__span {
  color: var(--font-color);
}

.active p {
  color: var(--secondary);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.pc-swal__container {
  min-width: 400px;
  max-width: 700px;
}

.pc-list p.my-0 {
  text-align: left;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: var(--border-color);
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
  width: 5px;
  height: 1px;
  cursor: pointer;
}

h1 {
  font-weight: normal;
}

main {
  flex-direction: row;
}

main > div:not(.login) {
  padding-bottom: 48px;
}

aside {
  background: var(--aside-background);
  color: white;
}

html .ui-aside svg {
  width: 40px !important;
  margin: 0;
}

.radius {
  border-top-left-radius: var(--border-radius, 4px);
  border-top-right-radius: var(--border-radius, 4px);
}

.ui-list-item-meta {
  text-transform: uppercase;
}

.border-primary {
  border: 2px solid var(--primary);
}

.border-transparent {
  border: 2px solid transparent;
}

.stroke-primary path {
  stroke: var(--primary);
  fill: var(--primary);
}

ul {
  list-style: none;
  padding-left: 0;
}

.pc-list__extendable {
  height: 200px;
  overflow-y: auto;
}

.ui-form-fetch {
  min-width: 500px;
  max-width: 768px;
}

.min500 {
  min-width: 500px;
}

.min200h {
  min-width: 200px;
}

.dashed {
  border: 1px dashed var(--primary);
}

.component {
  transition: 0.25s;
  opacity: 0;
  transform: translateX(-50px);
}

.entered {
  opacity: 1;
  transform: none;
}

main aside + * {
  max-width: 1000px;
  margin: 0 auto;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
}

.ui-aside .active img {
  filter: invert(44%) sepia(49%) saturate(2216%) hue-rotate(145deg)
    brightness(102%) contrast(101%);
}

.help--active img {
  filter: invert(44%) sepia(49%) saturate(2216%) hue-rotate(145deg)
    brightness(102%) contrast(101%);
}

.help::after {
  margin-top: 4px;
  content: " ";
  border-radius: 4px;
  background-color: transparent;
  width: 16px;
  height: 8px;
}

.help--active::after {
  background-color: var(--primary);
}

.st0 {
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.pc-card__inside {
  padding: 0 !important;
  background: var(--inside-color);
}

.commissions-square {
  min-height: 459px;
}

.currencies .commissions-square {
  min-height: auto;
}

a,
.commissions-square .pc-list-item,
.commission-square .pc-list-item,
.faq .pc-list-item {
  cursor: pointer;
}

.square__header {
  height: 51px;
}

.black {
  color: var(--font-color-on-background) !important;
}

.pc-select__span {
  font-size: 12px;
}

.pc-button.v3 {
  width: auto;
  background-color: var(--gold-color);
  color: var(--font-color-on-background);
  font-weight: normal;
}

.button {
  background: var(--gold-color);
  border-radius: 30px;
  color: var(--font-color-on-background);
}

.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.md-18 {
  font-size: 18px;
}
.md-24 {
  font-size: 24px;
}
.md-32 {
  font-size: 32px;
}
.md-36 {
  font-size: 36px;
}
.md-48 {
  font-size: 48px;
}

.pc-select__expand_more {
  top: 0px !important;
}

.login .pc-button {
  width: 100%;
  border-radius: var(--border-radius);
  margin-top: 10px;
}
