body {
  --font-family: "Source Sans Pro", sans-serif;
  --typography-spacing-vertical: 16px;
  --primary: #ffb300;
  --secondary: #00bca4;
  --form-element-active-border-color: #ffb300;
  --progress-color: #ffb300;
  --primary-hover: #ffb300cc;
  --aside-background-color: #1c2931;
  --form-label-font-weight: 600;
  --primary-inverse: black;

  --background: #121212;
  --aside-background: #1f1f1f;
  --border-color: #2e2e2e;
  --input-border-color: #f5f5f5;
  --inside-color: #1f1f1f;
  --header-background: #1f1f1f;
  --font-color-on-background: #1f1f1f;
  --font-color: #f5f5f5;
  --placeholder-color: #1f1f1f;
  --square-bottom-color: var(--border-color);
  --square-header-color: #2f2f2f;
}

.ui-aside {
  width: 175px;
}

li {
  margin: 0;
}

dialog.nospace > article {
  --block-spacing-horizontal: 0;
}

dialog.nospace > article footer,
dialog.nospace > article header {
  padding-left: calc(var(--spacing) * 1.5);
  padding-right: calc(var(--spacing) * 1.5);
}

.pc-pinfield__svg {
  right: 16px;
  bottom: 27px;
}

ul li {
  list-style-type: none;
}

a.table__pagination__a {
  padding: 4px;
}
