.ui-aside {
  padding: 24px;
  z-index: 2;
  background: var(--aside-background);
}

.ui-aside .items {
  position: sticky;
  top: 24px;
}

.ui-aside svg {
  width: 32px !important;
  margin: 8px 0;
}

.ui-aside svg:hover {
  cursor: pointer;
}

.ui-aside .active {
  color: var(--secondary);
}

.ui-aside .items > div > div {
  height: 50px;
}

.ui-aside .title {
  font-size: 22px;
}

aside {
  box-shadow: 0px 0px 2px #1c2931;
  width: 150px;
}

.ui-aside__action {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 30%;
  left: 135px;
  top: 40px;
  padding: 8px;
  background: white;
}

.ui-aside__action__a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30%;
  flex: 1;
  height: 100%;
  height: 50px;
  width: 50px;
}

@media (max-width: 767px) {
  .ui-aside {
    display: flex;
    flex-direction: column;
    order: 1;
    padding: 16px;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .ui-aside > div {
    display: flex;
    justify-content: space-between;
  }

  .ui-aside .items > div > div {
    height: 32px;
  }

  div .ui-aside p {
    font-size: 12px;
    margin-top: 12px;
  }

  .ui-aside .items > div {
    margin-bottom: 0;
  }

  .ui-aside .title {
    display: none;
  }
}

@media (max-width: 400px) {
  .ui-aside p {
    display: none;
  }

  .ui-aside svg {
    margin: 2px 0;
  }
}
