.faq ul {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.dark .faq ul {
  border-left: none;
  border-right: none;
}

.faq__list {
  background: var(--inside-color);
}
