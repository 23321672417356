.ui-circle {
  font-size: 24px;
  background: var(--header-background);
  border-radius: 50%;
  width: 200px;
  min-height: 200px;
}

.ui-circle-primary {
  border: 10px solid var(--gold-color);
}

.ui-circle-secondary {
  border: 10px solid var(--secondary);
}
