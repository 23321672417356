.apex__table {
  border-radius: 4px;
}

.apex__table__thead__tr,
.apex__table__tbody__tr {
  height: 56px;
}

.apex__table__tbody__td:not(:last-child),
.apex__table__thead__th {
  text-transform: capitalize;
  border-bottom: 1px solid var(--border-color);
  text-align: left;
}

.dark .apex__table__thead__tr {
  background-color: var(--inside-color);
}

.status_PROGRESS {
  color: #ffb300;
}

.status_EXECUTED {
  color: #2e7d32;
  opacity: 1;
}

.status_CANCELLED {
  color: #666;
  opacity: 1;
}

.dark .status_CANCELLED {
  color: #bbb;
  opacity: 1;
}
